import { TableNamesEnum } from 'enums/TableNamesEnum';

import { LOCAL_STORAGE_KEY } from 'store/actions/types';
import { LocalStorage } from 'utils/LocalStorage';

type StorageVersion = {
  tableNames: string[];
  version: string;
};

// clearing the storage only happens if the version is upgraded
// if you specify a location, only the specified locations will be cleared in the storage
const STORAGE_VERSION: StorageVersion = {
  version: '0.6',
  tableNames: [TableNamesEnum.AccountStatements],
};

const setStorageVersion = () =>
  LocalStorage.setItem(LOCAL_STORAGE_KEY.STORAGE_VERSION, STORAGE_VERSION);

const getClearedTableSettings = () =>
  STORAGE_VERSION.tableNames.reduce(
    (tableSetting, tableName) => ({ ...tableSetting, [tableName]: null }),
    {},
  );

const clearTableSettings = () => {
  const tableSettings = LocalStorage.getItem(LOCAL_STORAGE_KEY.TABLE_SETTINGS);
  const clearedTableSettings = getClearedTableSettings();

  LocalStorage.setItem(LOCAL_STORAGE_KEY.TABLE_SETTINGS, {
    ...tableSettings,
    ...clearedTableSettings,
  });
};

const getStorageVersion = () => {
  const storageVersion = LocalStorage.getItem(LOCAL_STORAGE_KEY.STORAGE_VERSION, {});
  const isObjectStorage = typeof storageVersion === 'object';

  return isObjectStorage ? storageVersion.version : storageVersion;
};

export const shouldClearLocalStorage = () => {
  const version = getStorageVersion();

  if (version !== STORAGE_VERSION.version) {
    STORAGE_VERSION.tableNames.length > 0 ? clearTableSettings() : LocalStorage.clear();
    setStorageVersion();
  }
};
