import * as React from 'react';

import { api } from 'api';
import { OrderType } from 'api/DemandsService/enums';
import { DemandResponse, PartialDemandRequest } from 'api/DemandsService/interfaces';
import { CheckSmsActionEnum, OrderState } from 'api/enums';
import { TransferSourceFieldsReturn } from 'api/interfaces';
import { useOrder } from 'components/Document/useOrder';
import { FetchOrderCb, OnSaveCreator, withOrder } from 'components/Document/withOrder';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { SmsModal } from 'components/modals/SmsModal';
import * as format from 'components/utils/format';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { templateActions } from 'pages/Handbooks/OrderTemplates/utils';
import { ForeignPaymentDetails } from 'pages/Payments/ForeignPayment/ForeignPaymentDetails';
import {
  foreignTransferSourceFields,
  prepareFieldsForeignPayment,
  prepareRequestDataForeignPayment,
  priorityTypes,
} from 'pages/Payments/ForeignPayment/ForeignPaymentPage';
import { DomesticTransferDetails } from 'pages/Payments/InternalPayment/DomesticTransferDetails';
import {
  DomesticSourceFieldsEnum,
  InternalSourceFieldsEnum,
} from 'pages/Payments/InternalPayment/enums';
import {
  prepareFieldsInternalPayment,
  prepareRequestDataInternalPayment,
} from 'pages/Payments/InternalPayment/helpers';
import { InternalTransferDetails } from 'pages/Payments/InternalPayment/InternalTransferDetails';
import {
  createOnSave as saveSalaryOrder,
  fetchOrder as fetchSalaryTemplate,
  SalaryPageComponent,
} from 'pages/Salary/SalaryPage';
import { isExistOrder } from 'utils/isData';

const fetchOrder: FetchOrderCb<any, any> = async (data, effects) => {
  const { routeParams } = data;
  const doc = await api.order.getNewOrderModel<DemandResponse>(routeParams.id);

  let sourceFields: TransferSourceFieldsReturn = {} as TransferSourceFieldsReturn;
  let label = '';
  let detailFields;
  let payload;
  const isDraft = doc.base.orderState === OrderState.Draft;

  switch (doc.base.orderType) {
    case OrderType.DomesticTransfer:
    case OrderType.DomesticTransferESCROU:
      label = translate('front.internal-payment-page.order-external-type.title.label');

      sourceFields = await api.payments.getDomesticTransferSourceFields(
        Object.values(DomesticSourceFieldsEnum),
        false,
        doc.base.customerId,
      );
      detailFields = prepareFieldsInternalPayment({
        doc,
        isDraft,
        isNew: true,
        payerAccounts: sourceFields.accountsOptions,
      });
      break;

    case OrderType.InternalTransfer:
    case OrderType.InternalTransferESCROU:
      label = translate('front.internal-payment-page.order-internal-type.title.label');
      // tslint:disable-next-line: max-line-length
      sourceFields = await api.payments.getInternalTransferSourceFields(
        Object.values(InternalSourceFieldsEnum),
        false,
        doc.base.customerId,
      );
      detailFields = prepareFieldsInternalPayment({
        doc,
        isDraft,
        isNew: true,
        payerAccounts: sourceFields.accountsOptions,
        receiverAccounts: sourceFields.creditAccountsOptions,
      });
      break;

    case OrderType.ForeignTransfer:
    case OrderType.ForeignTransferESCROU:
      label = translate('front.internal-payment-page.order-foreign-type.title.label');

      sourceFields = await api.payments.getInternationalTransferSourceFields(
        foreignTransferSourceFields,
        false,
        doc.base.customerId,
      );

      detailFields = prepareFieldsForeignPayment(
        doc,
        sourceFields.accountsOptions,
        sourceFields.feeAccountsOptions,
        isDraft,
      );

      payload = {
        priorityTypes,
        feeTypesOptions: sourceFields.feeTypesOptions,
      };

      break;

    case OrderType.SalarySheet:
      return fetchSalaryTemplate(data, effects);
  }

  const {
    accountsOptions: payerAccountOptions,
    creditAccountsOptions: receiverAccountOptions,
    receiverOptions,
    budgetsOptions,
    localBanksOptions,
    countriesOptions,
    countryOptions,
    purposes,
    feeAccountsOptions,
  } = sourceFields;

  effects.setPayload({
    ...payload,
    receiverAccountOptions,
    payerAccountOptions,
    budgetsOptions,
    localBanksOptions,
    purposes,
    receiverOptions,
    feeAccountsOptions,
    countriesOptions,
    countryOptions,
    allReceiverAccountOptions: receiverAccountOptions,
    allPayerAccountOptions: payerAccountOptions,
    isoCode: detailFields.isoCode,
  });

  return {
    label,
    actions: templateActions,
    customerId: doc.base.customerId,
    detailFields: { ...detailFields, orderAlias: doc.base.orderAlias },
    id: doc.id,
    date: format.parseDate(doc.base.orderDate),
    state: doc.base.orderState,
    stateTranslate: doc.base.orderState,
    number: doc.base.orderNumber,
    type: doc.base.orderType,
    orderKind: doc.base.orderKind,
  };
};

const createOnSave: OnSaveCreator<any, any> = (order, pld) => async (fieldsData, options) => {
  if (!isExistOrder(order)) {
    return null;
  }

  const otpCode = await confirmModal<string>(SmsModal, {
    action: CheckSmsActionEnum.CONFIRM_TEMPLATE_UPDATE,
  });

  if (!otpCode) return;

  const { orderAlias, orderDate, ...formData } = fieldsData;
  let orderRequest: PartialDemandRequest;
  switch (order.type) {
    case OrderType.SalarySheet:
      return await saveSalaryOrder(order, pld)(fieldsData, { templateName: orderAlias });
    case OrderType.DomesticTransfer:
    case OrderType.InternalTransfer:
    case OrderType.DomesticTransferESCROU:
    case OrderType.InternalTransferESCROU:
      orderRequest = await prepareRequestDataInternalPayment({
        order,
        options,
        formData,
        pld,
      });
      break;
    case OrderType.ForeignTransfer:
    case OrderType.ForeignTransferESCROU:
      orderRequest = await prepareRequestDataForeignPayment({
        order,
        options,
        formData,
        pld,
      });
  }

  orderRequest.base.orderAlias = orderAlias;

  return await api.payments.updatePayment(order.id, { ...orderRequest });
};

const OrderTemplateComponent: React.FC = () => {
  const { order } = useOrder();

  switch (order.type) {
    case OrderType.ForeignTransfer:
    case OrderType.ForeignTransferESCROU:
      return <ForeignPaymentDetails />;

    case OrderType.DomesticTransfer:
    case OrderType.DomesticTransferESCROU:
      return <DomesticTransferDetails />;

    case OrderType.InternalTransfer:
    case OrderType.InternalTransferESCROU:
      return <InternalTransferDetails />;

    case OrderType.SalarySheet:
      return <SalaryPageComponent isTemplate />;

    default:
      return null;
  }
};

export const OrderTemplate = withOrder(({ order }) => ({
  fetchOrder,
  createOnSave,
  waitForOrder: true,
  allowTemplateName: true,
  allowSave:
    order?.type === OrderType.SalarySheet
      ? isActionAllowed([Privileges.salarySheetEdit], order?.customerId)
      : true,
  allowViewOrder:
    order?.type === OrderType.SalarySheet
      ? isActionAllowed([Privileges.salarySheetView], order?.customerId)
      : true,
  afterSubmit: () => {
    goto(pages.handbooks.tabs.orderTemplates);
  },
}))(OrderTemplateComponent);
