import { AccountTransferSourceFields } from 'api/interfaces';
import { ErrorTypeEnum } from 'api/PaymentService/enums';
import { MetaParams } from 'api/Service';
import { OrderParams } from 'navigations/pages';
import { DebtQueryInterface } from 'pages/Login/CustomerSelectModal/DebtModal/interface';
import { getOnlyAnalyticAccount } from 'pages/Payments/InternalPayment/utils';
import { AgreementModalResult } from 'pages/utils/SignOrders/enums';
import { isExist } from 'utils/isData';

interface PreparePurposeInterface {
  activeAccounts: AccountTransferSourceFields['ACCOUNTS'];
  params: OrderParams<DebtQueryInterface>;
  purpose: AccountTransferSourceFields['ACCOUNT_TRANSFER_PAYMENT_PURPOSE'];
  REPORTS_FOR_PURPOSE_DEBT: AccountTransferSourceFields['REPORTS_FOR_PURPOSE_DEBT'];
}

interface ReportForPurposeDebtInterace {
  info: Array<{
    masks: string[];
    messages: string;
  }>;
}

export const preparePurpose = ({
  activeAccounts,
  purpose,
  REPORTS_FOR_PURPOSE_DEBT,
  params,
}: PreparePurposeInterface): string => {
  if (params?.isDebt) {
    const receiverAccount = activeAccounts.find(({ id }) => id === +params?.receiverAccount);

    if (!REPORTS_FOR_PURPOSE_DEBT || !isExist(receiverAccount)) return '';

    const maskAccount = getOnlyAnalyticAccount(receiverAccount.iban || '').substring(0, 4);
    const reportForDebt = JSON.parse(REPORTS_FOR_PURPOSE_DEBT) as ReportForPurposeDebtInterace;

    const purposeTemplate = reportForDebt.info.find(({ masks }) => masks.includes(maskAccount))
      ?.messages;

    return purposeTemplate
      ? purposeTemplate
          .replace('${CONTRACT_NUMBER}', receiverAccount.contractNumber)
          .replace('${CONTRACT_DATE}', receiverAccount.contractDate)
      : '';
  }

  return purpose;
};

export const isTypeErrorSkipped = (hasRenameOrders: AgreementModalResult, params: MetaParams) => {
  const skippedErrorType = {
    [AgreementModalResult.No]: ErrorTypeEnum.Renaming,
    [AgreementModalResult.Yes]: ErrorTypeEnum.Default,
  };

  return params?.ERROR_TYPE !== skippedErrorType[hasRenameOrders];
};
