import * as React from 'react';

import { checkMultiCustomer } from 'api/ConstantService';
import { AccountType } from 'api/enums';
import { api } from 'api/index';
import { ExportFormatEnum } from 'components/ExportDropdown/enum';
import { getExportActions } from 'components/ExportDropdown/ExportDropdown';
import { StatusColor } from 'components/layout/Status';
import { Fields } from 'pages/Analytics/AnalyticsFilter';
import { ProductEdit } from 'pages/Products/Accounts/AccountEditPage';
import { ExtraFields } from 'pages/Products/Credits/interfaces';

import { downloadFile, downloadRequisites, get, post, put } from './backend';
import { GridRequest, GridResponse, HasId, Option } from './Service';

export enum AccountActions {
  STATEMENT = 'statement',
  HISTORY = 'history',
  TRANSFER = 'transfer',
  PAYMENT = 'paymentFromAccount',
  INT_TRANSFER = 'intTransferOrder',
  REQUISITES = 'sendRequisites',
}

export enum DepositActions {
  STATEMENT = 'statement',
  HISTORY = 'history',
  TRANSFER = 'transfer',
}

export enum CreditActions {
  STATEMENT = 'statement',
  HISTORY = 'history',
  TRANSFER = 'transfer',
}

export enum ProductType {
  ACCOUNT = 'Account',
  DEPOSIT = 'Deposit',
  CREDIT = 'Credit',
  CARD = 'Card',
}

export enum ProductStatus {
  Active = 'Active',
  Blocked = 'Blocked',
  Closed = 'Closed',
  New = 'New',
}

export type CreditView = {
  credit: Credit;
  product: CreditProduct;
  params?: Array<Params>;
  subProducts?: Array<Product>;
};

export type CreditDebtView = {
  COMISSION?: number;
  EXPIRATION_BODY?: number;
  EXPIRATION_PERCENT?: number;
  MAIN?: number;
  PERCENT?: number;
};

export type DepositView = {
  deposit: Deposit;
  product: DepositProduct;
};

export type ProductFilter = {
  amountFrom?: number;
  amountTo?: number;
  currency?: string;
  customerIds?: number[];
  maxAmountLimit?: number;
  maxBalance?: number;
  minAmountLimit?: number;
  minBalance?: number;
  status?: string;
  subTypes?: string[];
  text?: string;
};

export type CardView = {
  cardName: string;
  customerId: number;
  id: number;
  accountId?: number;
  accountNumber?: string;
  accountStatus?: string;
  accountStatusTranslation?: string;
  actions?: Array<ProductActionDTO>;
  availableCreditLimit?: string;
  balance?: string;
  bankBranch?: string;
  blockedSum?: number;
  blockUnblockReason?: string;
  cardNumber?: string;
  cardProductType?: string;
  cardType?: string;
  contractNumber?: string;
  creditLimit?: string;
  currency?: string;
  currencyName?: string;
  displayOrder?: number;
  externalId?: string;
  fineAmount?: string;
  finishDate?: string;
  iban?: string;
  isLockable?: boolean;
  organization?: string;
  ownerName?: string;
  plannedBalance?: string;
  productName?: string;
  startDate?: string;
  status?: string;
  statusTranslation?: string;
  visible?: string;
};

export type AccountView = {
  allowCredit: boolean;
  allowDebit: boolean;
  allowStatement: boolean;
  id: number;
  actions?: Array<ProductActionDTO<AccountActions>>;
  alias?: string;
  amount?: number;
  arrestedAmount?: number;
  balance?: number;
  balanceByDocuments?: number;
  bankBranchShortName?: string;
  blockedAmount?: number;
  blockReason?: string;
  closedDate?: Date;
  currency?: string;
  currencyName?: string;
  customerFullName?: string;
  customerId?: number;
  customerName?: string;
  displayOrder?: number;
  iban?: string;
  label?: string;
  lastExpenseTime?: string;
  lastIncomeTime?: string;
  number?: string;
  openedDate?: Date;
  overdraftLimit?: number;
  plannedBalance?: number;
  productName?: string;
  status?: string;
  statusTranslation?: string;
  type?: AccountType;
  typeTranslation?: string;
  visible?: boolean;
};

export type ProductActionDTO<T = string> = {
  action?: T;
  actionTranslation?: string;
};

export type Credit = {
  amountInterest: number;
  amountInterestNc: number;
  amountInterestOverdue: number;
  amountInterestOverdueNc: number;
  amountOverdue: number;
  amountOverdueNc: number;
  contractAmount: number;
  effectivePercent: number;
  limit: number;
  nextPayDate: string;
  percent: number;
  term: number;
  valueDate: string;
};

export type CreditProduct = {
  actions: Array<ProductActionDTO<CreditActions>>;
  alias: string;
  amount: number;
  amountNc: number;
  bankBranchId: number;
  bankBranchName: string;
  closed: Date;
  code: string;
  currency: string;
  customerId: number;
  displayOrder: number;
  id: number;
  name: string;
  opened: Date;
  organization: string;
  params: Array<ProductParam>;
  status: ProductStatus;
  statusName: string;
  subType: string;
  subTypeName: string;
  type: ProductType;
  typeName: string;
};

export type Product = {
  actions: Array<ProductActionDTO>;
  alias: string;
  amount: number;
  amountNc: number;
  bankBranchId: number;
  bankBranchName: string;
  closed: Date;
  code: string;
  currency: string;
  customerId: number;
  displayOrder: number;
  id: number;
  name: string;
  opened: Date;
  organization: string;
  params: Array<ProductParam>;
  status: ProductStatus;
  statusName: string;
  subType: string;
  subTypeName: string;
  type: ProductType;
  typeName: string;
};

export type ProductParam = {
  id: number;
  type: string;
  value: string;
  accountId?: number;
  linkedProductId?: number;
};

export type DepositProduct = {
  actions: Array<ProductActionDTO<DepositActions>>;
  alias: string;
  amount: number;
  amountNc: number;
  bankBranchId: number;
  bankBranchName: string;
  closed: string;
  code: string;
  currency: string;
  customerId: number;
  displayOrder: number;
  id: number;
  name: string;
  opened: string;
  organization: string;
  params: Array<ProductParam>;
  status: string;
  statusName: string;
  subType: string;
  subTypeName: string;
  type: string;
  typeName: string;
};

type Deposit = {
  amountInterest: number;
  contractAmount: number;
  nextAccrualDate: string;
  percent: number;
  term: number;
};

type BankCorrespondent = {
  currency: string;
  name: string;
  number: string;
  swift: string;
};

export interface Balance {
  UAH: string;
}

export interface ProductResponse<View> {
  accountDetails?: View;
  productView?: View;
}

interface ProductsFilterInterface extends GridRequest {
  allowStatement?: boolean;
  amountFrom?: number;
  amountTo?: number;
  cardTypes?: string[];
  currency?: string;
  customerIds?: number[];
  excludedStatuses?: ProductStatus[];
  forAll?: boolean;
  organizationName?: string;
  status?: string;
  subTypes?: string[];
  text?: string;
  type?: string;
}

interface Params {
  type: string;
  value: string;
}

export interface RequisitesParams {
  accountId: number;
  withIban: boolean;
}

interface AccountParams {
  alias: string;
  allowCredit: boolean;
  allowDebit: boolean;
  allowStatement: boolean;
  balance: string;
  cardNumber: string;
  contractNumber: string;
  currency: string;
  currencyDigital: string;
  expirationDate: Date;
  iban: string;
  id: number;
  isVisible: boolean;
  number: string;
  plannedBalance: string;
  type: string;
}

interface AccountCurrency {
  digitalCode: string;
  fullName: string;
  isoCode: string;
  name: string;
  symbol: string;
}

export interface AccountRequisites {
  account?: AccountParams;
  accountCurrencyInfo?: AccountCurrency;
  bankAddress?: string;
  bankContacts?: string;
  bankCorrespondentInfos?: Array<BankCorrespondent>;
  bankName?: string;
  bankShortName?: string;
  bik?: string;
  bin?: string;
  customerFullName?: string;
  customerIntlName?: string;
  customerName?: string;
  customerTaxCode?: string;
  iban?: string;
  intBankAddress?: string;
  internationalBankName?: string;
  mfo?: string;
  paymentPurposeText?: string;
}

export interface CardLimitsRequest {
  cardId: number;
  period: number;
  size: number;
}

interface Card {
  label: string;
  value: string;
  id?: number;
}

export interface CardLimitView {
  cardId: number;
  code: number;
  customerId: number;
  id: number;
  limit: Card;
  maxAmount: number;
  maxCount: number;
  period: Card;
}

export interface CardLimitPeriodResponse {
  category: string;
  code: string;
  deleted: boolean;
  id: number;
  label: string;
  description?: string;
  descriptionTranslation?: string;
}

export interface DepositOffer extends HasId {
  amount: number;
  calcPeriod: string;
  code: string;
  codeName: string;
  currency: string;
  customerBusinessSize: string;
  isActive: boolean;
  isEarlyReturnAllowed: boolean;
  isExtensionAllowed: boolean;
  isInterestCapitalizeAllowed: boolean;
  isPublicSector: boolean;
  isReplenishmentAllowed: boolean;
  name: string;
  nameId: number;
  params: Array<any>;
  period: number;
  periodMax: number;
  periodMin: number;
  productSubType: string;
  productType: string;
}

interface DepositInfoResponse {
  amountMax: number;
  amountMin: number;
  calcPeriodsDetails: CalcPeriodOption[];
  currencies: string[];
  customerBusinessSizes: {
    [name: string]: string;
  };
  periodMax: number;
  periodMin: number;
}

export interface DepositInfo {
  amountMax: number;
  amountMin: number;
  calcPeriodsDetails: CalcPeriodOption[];
  currencies: Option[];
  customerBusinessSizes: Option[];
  periodMax: number;
  periodMin: number;
}

interface ExportParams {
  formatType: ExportFormatEnum;
  productType: ProductType;
  filter?: ProductFilter;
}

interface ProductsFilterInfo {
  customerIds: number[];
  productType: string;
  statuses: string[];
  subTypes: string[];
}

export interface CalculatedDeposit {
  amount: number;
  calcPeriod: string;
  currency: string;
  customerBusinessSize: string;
  earlyReturnAllowed: boolean;
  extensionAllowed: boolean;
  interestCapitalizeAllowed: boolean;
  periodMax: number;
  periodMin: number;
  publicSector: boolean;
  replenishmentAllowed: boolean;
}

export interface CalcPeriodOption extends Option {
  isCapitalize: boolean;
}

export const EXPORT_TYPES_PRODUCT: Record<string, ExportFormatEnum> = {
  XLS: ExportFormatEnum.EXCEL,
  CSV: ExportFormatEnum.CSV,
  DBF: ExportFormatEnum.DBF,
};

export const useExportActions = (filter: ExtraFields, productType: ProductType) =>
  React.useMemo(() => {
    const action = (formatType: ExportFormatEnum) => () =>
      api.products.exportProduct({
        formatType,
        productType,
        filter,
      });

    return getExportActions({
      action,
      exportTypes: EXPORT_TYPES_PRODUCT,
    });
  }, [filter, productType]);

const parseProductAddId = ({
  total,
  rows,
  productCounts,
}: GridResponse<DepositView | CreditView> & { productCounts: number }) => ({
  total,
  productCounts,
  rows: rows.map(p => ({ ...p, id: p.product.id })),
});

export const getStatusColor = (status: ProductStatus): StatusColor => {
  switch (status.toLocaleLowerCase()) {
    case ProductStatus.Active.toLocaleLowerCase():
      return 'green';
    case ProductStatus.Blocked.toLocaleLowerCase():
      return 'red';
    default:
      return 'grey';
  }
};

export const isActionAvailable = <ActionType>(
  actions: Array<ProductActionDTO<ActionType>>,
  action: ActionType,
): boolean => {
  return !!actions && actions.some(item => item.action === action);
};

export class ProductsService {
  async getAccounts(request: ProductsFilterInterface = {}): Promise<GridResponse<AccountView>> {
    return await get('/v4/accounts', checkMultiCustomer(request));
  }

  async getAccount(id: number): Promise<ProductResponse<AccountView>> {
    return await get(`/v1/accounts/${id}`);
  }

  async updateAccount(request: ProductEdit): Promise<void> {
    return await put('/account', request);
  }

  async getDeposits(
    request: ProductsFilterInterface = {},
  ): Promise<GridResponse<DepositView & HasId>> {
    const deposits = await get('/v1/products/deposits', checkMultiCustomer(request));
    return parseProductAddId(deposits) as GridResponse<DepositView & HasId>;
  }

  async getDeposit(id: number): Promise<DepositView> {
    return await get(`/v1/products/deposits/${id}`);
  }

  async updateDeposit(request: ProductEdit): Promise<void> {
    return await put('/v1/products', request);
  }

  async getProductsForFilter(filter: ProductsFilterInfo): Promise<GridResponse<any>> {
    return await get('/v1/products', filter);
  }

  async getCalculateDeposits(request: CalculatedDeposit): Promise<GridResponse<DepositOffer>> {
    return await post('/v1/product/calculator', undefined, request);
  }

  async getCalculateDepositInfo(): Promise<DepositInfo> {
    const response: DepositInfoResponse = await get('/v1/product/calculator/render');
    const { customerBusinessSizes, currencies, calcPeriodsDetails, ...other } = response;

    const newCustomerBusinessSizes = Object.entries(customerBusinessSizes).map(
      ([value, label]) => ({ value, label }),
    );
    const newCurrencies = currencies.map(v => ({ value: v, label: v }));

    return {
      customerBusinessSizes: newCustomerBusinessSizes,
      currencies: newCurrencies,
      calcPeriodsDetails: calcPeriodsDetails,
      ...other,
    };
  }

  async getCredits(request: ProductsFilterInterface): Promise<GridResponse<CreditView & HasId>> {
    const credits = await get('/v1/products/credits', checkMultiCustomer(request));

    return parseProductAddId(credits) as GridResponse<CreditView & HasId>;
  }

  async getCredit(id: number): Promise<CreditView> {
    return await get(`/v1/products/credits/${id}`);
  }

  async updateCredit(request: ProductEdit): Promise<void> {
    return await put('/v1/products', request);
  }

  async getCreditDebt(id: number): Promise<CreditDebtView> {
    return await get(`/v1/products/credits/debt/${id}`);
  }

  async getCards(request: ProductsFilterInterface): Promise<GridResponse<CardView>> {
    return await get('/card/get-cards-by-customer', checkMultiCustomer(request));
  }

  async getCard(id: string): Promise<ProductResponse<CardView>> {
    return await get(`/v1/cards/${id}`);
  }

  async updateCard(request: CardView): Promise<void> {
    return await put('/card', request);
  }

  async sendRequisites(request: RequisitesParams): Promise<AccountRequisites> {
    return await get('/account/send-requisites', request);
  }

  async getAccRequisitesPDF(id: string | number): Promise<void> {
    return await downloadRequisites(`/account/send-requisites/export/${id}`);
  }

  async getCardLimits(request: CardLimitsRequest): Promise<CardLimitView[]> {
    return await get('/v1/cards/limits', request);
  }

  async getCardLimitPeriods(): Promise<Array<CardLimitPeriodResponse>> {
    return await get('/codes/by/CardLimitPeriod');
  }

  async saveCardLimit(data: Partial<CardLimitView>): Promise<number> {
    return await post('/v1/cards/limits/', data);
  }

  async exportProduct({ productType, formatType, filter }: ExportParams): Promise<any> {
    const {
      status,
      minBalance,
      maxBalance,
      amountFrom,
      amountTo,
      minAmountLimit,
      maxAmountLimit,
      ...otherFilter
    } = filter;
    const statusNew = status !== 'all' ? status : undefined;
    const amountMin = minBalance || amountFrom || minAmountLimit || undefined;
    const amountMax = maxBalance || amountTo || maxAmountLimit || undefined;
    return downloadFile(
      '/v1/products/export',
      checkMultiCustomer({
        formatType,
        productType,
        ...otherFilter,
        status: statusNew,
        amountFrom: amountMin,
        amountTo: amountMax,
      }),
    );
  }

  async exportGraph(filter: Fields, exportType: ExportFormatEnum): Promise<any> {
    return downloadFile('/v1/account/graph/export', {}, { ...filter, exportType });
  }
}
