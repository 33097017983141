import { api } from 'api';
import { CheckSmsActionEnum, OrderActionType } from 'api/enums';
import { OrderAction } from 'api/OrderService';
import { confirmModal } from 'components/modals/globalModal/GlobalModal';
import { SmsModal } from 'components/modals/SmsModal';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { getTranslateAction } from 'pages/Payments/general';

export const templateActions: OrderAction[] = [
  {
    label: getTranslateAction(OrderActionType.DELETE),
    name: OrderActionType.DELETE,
    type: 'dropdown',
    onClick: async order => {
      const otpCode = await confirmModal<string>(SmsModal, {
        action: CheckSmsActionEnum.CONFIRM_TEMPLATE_DELETE,
      });

      if (!otpCode) return;

      await api.handbooks.deleteOrderTemplate(order.id);
      goto(pages.handbooks.tabs.orderTemplates);
    },
    confirmMessage: 'front.document-templates-table.confirm-delete.label',
  },
];
