import React from 'react';

import moment from 'moment';

import { api } from 'api';
import { DemandType, OrderType } from 'api/DemandsService/enums';
import { DemandResponse, DynamicFieldEssentials } from 'api/DemandsService/interfaces';
import { EmployeeStatus, OrderKind, OrderState } from 'api/enums';
import { Order } from 'api/OrderService';
import { Employee } from 'api/SalaryService';
import { useOrder } from 'components/Document/useOrder';
import { isDisabled } from 'components/Document/utils';
import { FetchOrderCb, OnSaveCreator, withOrder } from 'components/Document/withOrder';
import { FormState } from 'components/forms/ValidatingForm/FormContext';
import { confirmationModal } from 'components/modals/ConfirmModal';
import { NavTab, NavTabs } from 'components/tabs/NavTabs';
import config from 'config';
import { translate } from 'i18n/translator';
import { isActionAllowed } from 'navigations/access';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { Privileges } from 'navigations/privileges';
import { NEW } from 'navigations/routes';
import {
  getActions,
  getDetailFields,
  getFieldNames,
  getPayload,
  makeFields,
} from 'pages/Demands/utils';
import { templateActions } from 'pages/Handbooks/OrderTemplates/utils';
import { PaymentHistoryPage } from 'pages/Payments/History/PaymentHistoryPage';
import { SalaryNames } from 'pages/Salary/enums';
import { CustomError } from 'utils/customError';
import { useEffectIfValueChange } from 'utils/hooks';
import { isExist } from 'utils/isData';

import { SalaryDetails } from './SalaryDetails';
import { SalaryRegistry } from './SalaryRegistry';
import { SalaryRelatedPayments } from './SalaryRelatedPayments';
import { toDynamicFieldsSalary } from './utils';

interface Payload extends DynamicFieldEssentials {
  isImported?: boolean;
  relatedPayments?: DemandResponse[];
  salaryDeal?: number;
  selectedEmployees?: Employee[];
}

interface EmployeeRequest {
  amount: number | string;
  employeeId: number;
}

// TODO check and add missing fields
export interface SalaryFields {
  customerId: number;
  amount?: number;
  currency?: string;
  orderDate?: string;
  orderNumber?: any;
  salaryDeal?: string;
}

export interface SalaryRequest {
  checkAllConstraints: boolean;
  dynamicFields: Obj;
  employees: Array<EmployeeRequest>;
  fields: SalaryFields;
  generateOrders: boolean;
  orderType: string;
  template: boolean;
  templateName: string;
}

export const SalaryPageComponent: React.FC<{ isTemplate: boolean }> = ({ isTemplate = false }) => {
  const {
    order,
    payload: { selectedEmployees, relatedPayments },
    setPayload,
    form,
  } = useOrder<SalaryFields, Payload>();

  const salaryDeal = form.getFieldValue(SalaryNames.SalaryDeal);

  const setSelectedEmployees = React.useCallback(
    (selectedEmployees, merge?: boolean) => {
      setPayload(state => {
        let newSelectedEmployees;
        if (merge) {
          newSelectedEmployees = [...state.selectedEmployees, ...selectedEmployees];
        } else {
          newSelectedEmployees = selectedEmployees;
        }

        return {
          ...state,
          selectedEmployees: newSelectedEmployees,
        };
      });
      form.setError(null);
    },
    [order],
  );

  const resetEmployees = React.useCallback(
    () =>
      setPayload(prevPayload => ({
        ...prevPayload,
        selectedEmployees: [],
      })),
    [salaryDeal],
  );

  useEffectIfValueChange(salaryDeal, resetEmployees);

  return (
    <NavTabs>
      <NavTab
        title={translate('front.payroll-modal.tab-requisites.label')}
        path={isTemplate ? pages.handbooks.orderTemplate(':id') : pages.salary.tabs.details(':id')}
      >
        <SalaryDetails />
      </NavTab>
      {salaryDeal && (
        <NavTab
          title={translate('front.payroll-modal.tab-registry.label')}
          path={
            isTemplate
              ? pages.handbooks.orderTemplateSalaryRegistry(':id')
              : pages.salary.tabs.registry(':id')
          }
        >
          <SalaryRegistry
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
            disabled={isDisabled(order) || form.disabled}
            productIds={[Number(salaryDeal)]}
          />
        </NavTab>
      )}
      {!!order?.id && !isTemplate && (
        <NavTab
          title={translate('front.payroll-modal.tab-history.label')}
          path={pages.salary.tabs.history(':id')}
        >
          <PaymentHistoryPage />
        </NavTab>
      )}
      {!!order?.id && !isTemplate && (
        <NavTab
          title={translate('front.payroll-modal.tab-related-payments.label')}
          path={pages.salary.tabs.relatedPayments(':id')}
        >
          <SalaryRelatedPayments data={relatedPayments} />
        </NavTab>
      )}
    </NavTabs>
  );
};

export const createOnSave: OnSaveCreator<SalaryFields, Payload> =
  (order, payload) => async (formData, options) => {
    const { orderNumber, orderDate, ...demandFields } = formData;

    if (!payload.selectedEmployees || !payload.selectedEmployees.length) {
      goto(pages.salary.tabs.registry(order.id || NEW));
      throw new CustomError(translate('front.demands.salary-page.error.fill-employees-list.label'));
    }

    if (payload.selectedEmployees.some(employee => !employee.amount)) {
      goto(pages.salary.tabs.registry(order.id || NEW));
      throw new CustomError(translate('front.demands.salary-page.error.set-amount.label'));
    }

    const isTemplate = !!options?.templateName;

    const fields: SalaryFields = {
      orderNumber,
      customerId: order.customerId,
      orderDate: moment(orderDate.toString()).format('YYYY-MM-DD'),
      currency: config.bank.nationalCurrency,
      amount: demandFields.salaryTotal as number,
    };

    const orderData: SalaryRequest = {
      orderType: order.type,
      checkAllConstraints: false,
      generateOrders: demandFields.makePayments as boolean,
      dynamicFields: toDynamicFieldsSalary(demandFields, payload.dynamicFields),
      fields,
      employees: payload.selectedEmployees.map(e => ({
        employeeId: e.id,
        amount: e.amount,
      })),
      template: isTemplate,
      templateName: options?.templateName,
    };

    let id = order.id;
    if (!id) {
      id = await api.salary.createSalary(orderData);
    } else {
      await api.salary.updateSalary(order.id, orderData);
    }

    return id;
  };

const fetchSalaryDetails = async (id: number) =>
  await Promise.all([
    api.demands.getDemand(id),
    api.salary.getEmployeesInDemand(id),
    api.salary.getRelated(id),
  ]);

export const fetchOrder: FetchOrderCb<SalaryFields, Payload> = async (
  params,
  { setPayload },
): Promise<Order> => {
  const { customerId, routeParams, locationState, queryParams, isFromTemplate } = params;
  const isImported = isExist(locationState?.importData);
  let demand,
    employees,
    relatedPayments: DemandResponse[],
    selectedEmployees: Employee[],
    fields,
    dynamicFields,
    detailFields: FormState,
    number: string;

  if (routeParams.id === NEW) {
    if (queryParams.copyFrom) {
      [demand, employees, relatedPayments] = await fetchSalaryDetails(Number(queryParams.copyFrom));

      dynamicFields = demand.dynamicFields;
      detailFields = getDetailFields(dynamicFields, OrderState.Draft);

      selectedEmployees = employees.rows.map(e => ({
        ...e,
        id: e.employeeId,
      }));
    }

    if (isImported) {
      const { order, employeeSalaryModels } = locationState.importData.salaryProjectModel;

      demand = order;
      dynamicFields = demand.dynamicFields;
      selectedEmployees = employeeSalaryModels;
      detailFields = getDetailFields(dynamicFields, OrderState.Draft);
    }

    if (!isImported && !queryParams.copyFrom) {
      demand = await api.demands.getDemandTemplate(OrderType.SalarySheet, customerId);

      selectedEmployees = [];
      dynamicFields = demand.orderFieldModel;

      detailFields = {
        makePayments: true,
      };
    }

    number = await api.payments.getDocumentNumber({
      customerId: demand.base?.customerId || customerId,
      orderType: OrderType.SalarySheet,
      orderDate: moment().format('YYYY-MM-DD'),
    });

    fields = {
      number,
      id: null,
      state: null,
      stateTranslate: null,
      type: OrderType.SalarySheet,
      customerId: demand?.base?.customerId || customerId,
      label: demand.orderTypeModel?.name.text || demand.base?.translatedOrderType,
      date: moment().toDate(),
      actions: [],
      detailFields,
    };
  }

  if (routeParams.id !== NEW) {
    [demand, employees, relatedPayments] = await fetchSalaryDetails(Number(routeParams.id));

    dynamicFields = demand.dynamicFields;
    selectedEmployees = employees.rows.map(e => ({ ...e, id: e.employeeId }));

    detailFields = {
      orderAlias: demand.base.orderAlias,
      ...getDetailFields(dynamicFields, demand.base.orderState),
    };

    if (isFromTemplate) {
      detailFields = {
        orderAlias: demand.base.orderAlias,
        ...getDetailFields(dynamicFields, OrderState.Draft),
      };

      selectedEmployees = await getActiveEmployees(detailFields.salaryDeal, selectedEmployees);

      if (selectedEmployees.length === 0) {
        return null;
      }
    }

    fields = {
      ...makeFields(demand, Number(routeParams.id)),
      actions: demand.base.orderKind === OrderKind.TEMPLATE ? templateActions : getActions(demand),
      detailFields,
      orderKind: demand.base.orderKind,
    };
  }

  const payload = getPayload(dynamicFields, getFieldNames(DemandType.SalarySheet));

  setPayload({
    ...payload,
    selectedEmployees,
    relatedPayments,
    isImported,
    filteredValues: {},
    dynamicFields,
  });

  return fields;
};

const getActiveEmployees = async (
  salaryDeal: number,
  employees: Employee[],
): Promise<Employee[]> => {
  const dealEmployeeIds = (
    await api.salary.getEmployees({
      statuses: EmployeeStatus.ACTIVE,
      productIds: [salaryDeal],
    })
  ).rows.map(employee => employee.id);

  const templateEmployeesCount = employees.length;
  const selectedEmployees = employees.filter(employee => dealEmployeeIds.includes(employee.id));

  if (selectedEmployees.length === 0) {
    // no active employees found. Template is not allowed for usage
    await confirmationModal('front.demands.salary-page.template.error.no_employees', true, false);
    return [];
  }

  if (templateEmployeesCount !== selectedEmployees.length) {
    // there are some inactive employees found
    const shouldUseTemplate = await confirmationModal(
      'front.demands.salary-page.template.error.some_inactive_employees',
      true,
      true,
    );

    if (!shouldUseTemplate) {
      return [];
    }
  }
  return selectedEmployees;
};

export const SalaryPage = withOrder<SalaryFields, Payload>(({ order }) => ({
  createOnSave,
  fetchOrder,
  allowState: true,
  allowDetails: true,
  allowTemplates: true,
  allowViewOrder: isActionAllowed([Privileges.salarySheetView], order?.customerId),
  allowSave: order && isActionAllowed([Privileges.salarySheetEdit], order.customerId),
  allowSign: order && isActionAllowed([Privileges.salarySheetSign], order.customerId),
  disableButtons: true,
  getCustomerFilteringPrivilege: () => Privileges.salarySheetEdit,
  afterSubmit: () => {
    goto(pages.salaries);
  },
}))(SalaryPageComponent);
