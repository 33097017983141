import * as React from 'react';

import { TableNamesEnum } from 'enums/TableNamesEnum';

import { api } from 'api';
import { TransactionHistoryEnum, TransactionHistoryStatus } from 'api/enums';
import { Transaction, TransactionsFilters } from 'api/interfaces';
import { getTotal, GridRequest, SortOrder, Turnover } from 'api/Service';
import { Page } from 'components/layout/Page/Page';
import { DataTable } from 'components/Table/DataTable';
import { today } from 'components/utils/format';
import { goto } from 'navigations/navigate';
import { pages } from 'navigations/pages';
import { columns } from 'pages/AccountStatements/columns';
import { getActions, MAX_VISIBLE_TABLE_ROWS } from 'pages/AccountStatements/utils';

import { AccountStatementFilter } from './AccountStatementFilter';
import { EmailModalProps } from './EmailModal';

export interface EmailProps extends EmailModalProps {
  params: {
    accountIds?: Array<number | string>;
    dateFrom?: string;
    dateTo?: string;
    ids?: Array<number | string>;
  };
}

const DEFAULT_SORT = ['executed', 'documentNumber'];

const StatementsFilter = () => <AccountStatementFilter getActions={getActions} hasRevaluation />;

const fetchTransactions = async (request: TransactionsFilters & GridRequest) => {
  const { shouldUseIntegration, ...otherRequest } = request;

  const { rows, total } = await api.payments.getTransactionHistory({
    ...otherRequest,
    dateFrom: request.dateFrom ? request.dateFrom : today(),
    statuses: [TransactionHistoryStatus.EXECUTED],
    withCurrencyTotals: true,
    withSync: shouldUseIntegration,
  });

  const newRows: (Transaction & Turnover)[] = rows.map(row => ({
    isEntrancePayment: row.type === TransactionHistoryEnum.IN,
    ...row,
    ...total.turnover[row.accountId],
  }));

  return {
    rows: newRows,
    total: getTotal(total),
  };
};

export const AccountStatementsPage = () => (
  <Page title="front.account-statements-table.name.label">
    <DataTable
      name={TableNamesEnum.AccountStatements}
      columns={columns}
      fetchRows={fetchTransactions}
      defaultSort={DEFAULT_SORT}
      defaultOrder={SortOrder.Desc}
      filterComponent={StatementsFilter}
      maxVisibleTableRows={MAX_VISIBLE_TABLE_ROWS}
      onRowClick={order => goto(pages.transactionHistory.details(order.id))}
      shouldUseStorageSort
      shouldFetchIfChangedRoute={false}
    />
  </Page>
);
